import Head from 'next/head'
import styles from '../styles/Home.module.css'
import Link from 'next/link'


export default function Home() {
  return (
    <div className={styles.container}>
      <Head>
        <title>Hi I'm Daddie0</title>
        <meta name="description" content="A retard with mild coding skills" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <div className={styles.buttoncontainer}>
          <h1 className={styles.intro}>Hi, I'm <name className={styles.name}>Daddie0</name>!</h1>
        </div>

        <div className={styles.nav}>
          <a href="https://github.com/GoByeBye" target="_blank" rel="noopener noreferrer"><button className={styles.main_button}>GitHub</button></a>
          <Link href="/portfolio" passHref><button className={styles.main_button}>Portfolio</button></Link>
          <Link href="/contact" passHref><button className={styles.main_button}>Contact</button></Link>
          
        </div>
      </main>
    </div>
  )
}